<template>
  <router-view />
</template>

<script setup lang="ts">
import { useUserInfoStore } from './stores/user-info-store';
import { useQueryProvider } from "vue-query";
import { onMounted, onBeforeUnmount } from 'vue';
import { useAuth } from './composables/auth/useAuth';
import { useRouter } from "vue-router";
import { globalRouter } from "./router/globalRouter";
import { useAuthStore } from 'src/stores/auth-store';

useQueryProvider();
const userInfoStore = useUserInfoStore();
const authStore = useAuthStore();
const { refreshToken } = useAuth();
const router = useRouter();
globalRouter.router = router;
// Setup the event listener to check for changes in the isAuthenticated state
//
const checkAuthChange = (event) => {
    if (event.storageArea != localStorage) return;
    if (event.key === "auth") {
  location.reload();
  }
};
const deleteCookiesStartingWith = (prefix) => {
    const cookies = document.cookie.split("; ");
    cookies.forEach(cookie => {
        if (cookie.startsWith(prefix)) {
            const cookieName = cookie.split("=")[0];
            document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        }
    });
};
// try to get a new access token when the app starts
onMounted(async (): Promise<void> => {
  deleteCookiesStartingWith('teacher-reviews');
  await refreshToken(userInfoStore.id === '' ? true : false);
});

</script>

import { defineStore } from 'pinia';
import { useSessionStorage } from '@vueuse/core';

export const useMeetingStore = defineStore('meeting', {
  state: () => {
      return useSessionStorage('meeting-info', {
          courseId: '',
          courseName: '',
          lessonId: '',
          isAddedToLesson: false,
          isLessonStarted: false,
          startTime: '',
      });
  },
  actions: {
      setCourseId(courseId: string) {
          this.courseId = courseId;
      },
      setCourseName(courseName: string) {
          this.courseName = courseName;
      },
      setLessonId(lessonId: string) {
          this.lessonId = lessonId;
      },
      setRegistrationStatus(status: boolean) {
          this.isAddedToLesson = status;
      },
      setLessonStarted(started: boolean) {
          this.isLessonStarted = started;
      },
      setStartTime(startTime: string) {
          this.startTime = startTime;
      },
  }
});

import pino from 'pino';

// Highlight.io configuration for Pino
const highlightConfig = {
  projectID: '2d1rpnlg', // Replace with your actual Project ID
  serviceName: 'my-vue-pino-logger',
  serviceVersion: '1.0', // Use a dynamic version or a static string
};

const pinoConfig = {
  level: import.meta.env.VITE_PINO_LOG_LEVEL || 'info',
  browser: {
    write: {
      debug: (obj) => {
        // Avoid exposing logs to browser console
       //console.log('Custom debug handler:', obj);
      },
      info: (obj) => {
         // Avoid exposing logs to browser console
        //console.log('Custom INFO handler:', obj); 
      },
      error: (obj) => {
         // Avoid exposing logs to browser console
        //console.error('Custom ERROR handler:', obj); 
      },
    }
  },
  transport: {
    target: '@highlight-run/pino',
    options: highlightConfig,
    level: 'info',
  },
};

const logger = pino(pinoConfig);

export default logger;
import { defineStore } from 'pinia';
import AuthService from 'src/services/AuthService';
import { UserInfo } from '../types';
import { useLocalStorage } from '@vueuse/core';

export const useUserInfoStore = defineStore('user-info', {
  state: () => {
    return useLocalStorage('user-info', {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      type: '',
      avatar: '',
      is_email_verified: false,
      onboardingDone: undefined as Date | null | undefined,
      lastAcknowledgedLevel: 1,
      petName: '',
      stripe_product_id: '',
    });
  },
  getters: {},
  actions: {
    async fetchUserInfo() {
        const [error, data] =  await AuthService.getUserInfo();
        if (error) {
          console.error(error);
          return
        }
        if (data) {
          this.id = data.id;
          this.email = data.email;
          this.firstName = data.first_name;
          this.lastName = data.last_name;
          this.type = data.type;
          this.avatar = data.avatar;
          this.is_email_verified = data.is_email_verified;
          this.onboardingDone = data.user_metadata.onboarding_done as Date | null;
          this.lastAcknowledgedLevel = data.user_metadata.last_acknowledged_level;
          this.stripe_product_id = data.stripe_product_id;
        }
      
    },
    setUserInfo(userData: UserInfo) {
      this.id = userData.id;
      this.email = userData.email;
      this.firstName = userData.first_name;
      this.lastName = userData.last_name;
      this.type = userData.type;
      this.avatar = userData.avatar;
      this.is_email_verified = userData.is_email_verified;
      this.onboardingDone = userData.user_metadata?.onboarding_done as Date | null;
      this.lastAcknowledgedLevel = userData.user_metadata?.last_acknowledged_level || 1;
      this.stripe_product_id = userData.stripe_product_id;
    },
    clearUserInfo() {
      this.id = '';
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.type = '';
      this.avatar = '';
      this.is_email_verified = false;
      this.stripe_product_id = '';
    },
    setIsEmailVerified(isEmailVerified: boolean) {
      this.is_email_verified = isEmailVerified;
    },
    setPetName(petName: string) {
      this.petName = petName;
    },
    setStripeProductId(stripeProductId: string) {
      this.stripe_product_id = stripeProductId;
    },
    async fetchStripeProductId() {
      const [error, data] =  await AuthService.getUserInfo();
      if (error) {
        console.error(error);
        return
      }
      if (data) {
        this.stripe_product_id = data.stripe_product_id;
      }
    }
  },
  persist: true
});
